// @import packages
import React, { useState, useEffect } from "react";

// @import components
import BaseTimer from "./BaseTimer";

// @import assets
import { lastAudio, endAudio } from "../../assets/audio/audio.js";

// @import styling
import styled from "styled-components";

const FreeTimer = ({ timer }) => {
  const [firstTimer, secondTimer] = timer;
  const [firstTimerTime, setFirstTimerTime] = useState(firstTimer.time);
  const [secondTimerTime, setSecondTimerTime] = useState(secondTimer.time);
  const [firstTimerLastAudioPlayed, setFirstTimerLastAudioPlayed] =
    useState(false);
  const [secondTimerLastAudioPlayed, setSecondTimerLastAudioPlayed] =
    useState(false);
  const [firstTimerTimeEnd, setFirstTimerTimeEnd] = useState(false);
  const [secondTimerTimeEnd, setSecondTimerTimeEnd] = useState(false);
  const [firstTimerPlaying, setFirstTimerPlaying] = useState(false);
  const [timerActive, setTimerActive] = useState(false);
  const [timerPause, setTimerPause] = useState(false);

  // @desc useEffects
  // Timer countdown
  useEffect(() => {
    let interval = null;
    if (timerActive && !timerPause) {
      if (firstTimerPlaying && !firstTimerTimeEnd) {
        interval = setInterval(
          () => setFirstTimerTime((time) => time - 1),
          1000
        );
      } else if (!firstTimerPlaying && !secondTimerTimeEnd) {
        interval = setInterval(
          () => setSecondTimerTime((time) => time - 1),
          1000
        );
      } else {
        clearInterval(interval);
      }
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [
    firstTimerPlaying,
    timerActive,
    firstTimerTimeEnd,
    secondTimerTimeEnd,
    timerPause,
  ]);

  // 30 seconds and end audio
  useEffect(() => {
    if (secondTimerTime === 30 && !secondTimerLastAudioPlayed) {
      setSecondTimerLastAudioPlayed(true);
      lastAudio.play();
    }
    if (firstTimerTime === 30 && !firstTimerLastAudioPlayed) {
      setFirstTimerLastAudioPlayed(true);
      lastAudio.play();
    }
    if (firstTimerTime === 0 && !firstTimerTimeEnd) {
      endAudio.play();
      setFirstTimerTimeEnd(true);
    }
    if (secondTimerTime === 0 && !secondTimerTimeEnd) {
      endAudio.play();
      setSecondTimerTimeEnd(true);
    }
  }, [firstTimerTime, secondTimerTime, firstTimerTimeEnd, secondTimerTimeEnd]);

  // @desc Functions
  const handleUserStart = (firstStart = true) => {
    setFirstTimerPlaying(firstStart);
    setTimerActive(true);
  };

  const handleReset = () => {
    setTimerActive(false);
    setFirstTimerTime(firstTimer.time);
    setSecondTimerTime(secondTimer.time);
    setSecondTimerTimeEnd(false);
    setFirstTimerTimeEnd(false);
    setTimerPause(false);
    setSecondTimerLastAudioPlayed(false);
    setFirstTimerLastAudioPlayed(false);
  };

  const handleSwitch = () => {
    setFirstTimerPlaying(!firstTimerPlaying);
  };

  return (
    <>
      <DualBaseTimerDiv>
        <BaseTimer
          showIsActive={timerActive && firstTimerPlaying}
          name={firstTimer.name}
          time={firstTimerTime}
          timerActive={timerActive}
          handleStart={() => handleUserStart(true)}
          showControlsAfterStart={false}
        />
        <BaseTimer
          showIsActive={timerActive && !firstTimerPlaying}
          name={secondTimer.name}
          time={secondTimerTime}
          timerActive={timerActive}
          handleStart={() => handleUserStart(false)}
          showControlsAfterStart={false}
        />
      </DualBaseTimerDiv>
      {timerActive && (
        <NavigationToolbar>
          <button onClick={handleSwitch} class="btn btn-primary btn-lg mx-3">
            切换
          </button>
          <button
            onClick={() => setTimerPause(!timerPause)}
            className={`btn btn-${
              timerPause ? "success" : "danger"
            } btn-lg mx-3`}>
            {timerPause ? "继续" : "暂停"}
          </button>
          <button onClick={handleReset} class="btn btn-warning btn-lg mx-3">
            重置
          </button>
        </NavigationToolbar>
      )}
    </>
  );
};

// ------ Styled Component START ------
const DualBaseTimerDiv = styled.div`
  display: flex;
  width: 80%;
  /* align-items: center; */
  justify-content: space-around;
`;

const NavigationToolbar = styled.div`
  display: flex;
  /* button {
    width: 5rem;
    height: 2rem;
    margin: 0rem 5rem;
  } */
`;
// ------ Styled Component END ------

export default FreeTimer;
