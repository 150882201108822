import React from "react";
import Icon from "../../assets/img/Heng Ee Debate Club.png";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Layout = () => {
  const history = useHistory();
  return (
    <RightUpDiv>
      <IconImg
        src={Icon}
        onClick={() => {
          // history.push("/");
          window.location.href = "/";
        }}
      />
    </RightUpDiv>
  );
};

// styled components
const RightUpDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  z-index: 5;
`;

const IconImg = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 30%;
  cursor: pointer;
`;

export default Layout;
