// @import packages
import React from "react";

// @import components
import FreeTimer from "./FreeTimer";
import Timer from "./Timer";

const TimerGroup = ({ roundType, config }) => {
  return (
    <>
      {roundType !== "free" ? (
        <div className="d-flex flex-row w-75 justify-content-around">
          {config.map((timerObj, index) => (
            <Timer key={index} timerObj={timerObj} />
          ))}
        </div>
      ) : (
        <FreeTimer timer={config} />
      )}
    </>
  );
};

export default TimerGroup;
