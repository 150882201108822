// TODO Split component
// @import packages
import React, { useState, useEffect } from "react"; // React
import { v4 as uuidv4 } from "uuid"; // uuid
import { Link } from "react-router-dom"; // Route
import { FieldArray, Form, Formik } from "formik"; // Form
import * as Yup from "yup"; // Form Validation
import { toast } from "react-toastify"; // Toast

// @import utils
import axios from "../utils/axios";
import roundTypeSelect from "../utils/roundTypeSelect";

// @import components
import { Form as bsForm } from "react-bootstrap";
import errorExist from "../utils/FieldArrayError";
import AlertTimerCode from "../components/form/AlertTimerCode";
import VipLabel from "../components/form/VipLabel";

// @import styling
import styled from "styled-components";

const CreateTimer = () => {
  const [isSendingData, setIsSendingData] = useState(false);
  const formikInitialValues = {
    timerName: "",
    timerCode: "",
    timerRound: [],
    premiumCode: "",
  };

  useEffect(() => {
    document.title = "创建新计时器 | 辩论计时器";
  }, []);

  // @desc Functions
  const createFormSubmit = (values, setErrors, resetForm) => {
    setIsSendingData(true);
    const id = toast.loading("请稍等......", { autoClose: false });
    axios
      .post("addtimer", values)
      .then((res) => {
        toast.update(id, {
          render: <AlertTimerCode timerCode={res.data.code} />,
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
          isLoading: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
        console.log("Success request", res);
        resetForm();
        setIsSendingData(false);
      })
      .catch((err) => {
        setIsSendingData(false);
        toast.update(id, {
          render: "创建失败",
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          isLoading: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
        console.log("Error when sending data", err);
        console.log("Error.response.data => ", err.response.data);
        setErrors(err.response.data.error);
      });
  };

  const checkTimerCodeExist = (timerCode, setFieldError) => {
    const cleanTimerCode = timerCode.trim();
    if (cleanTimerCode !== "") {
      axios.post("tcexist", { timerCode: cleanTimerCode }).then((res) => {
        const { exist, message } = res.data;
        if (exist) {
          setFieldError("timerCode", message);
        }
      });
    }
  };

  const getTimerRoundConfig = (roundType) => {
    if (roundType !== "none") {
      let { timerCount } = roundTypeSelect.find(
        (obj) => obj.value === roundType
      );
      let configRound = [];
      for (let i = 0; i < timerCount; i++) {
        configRound.push({ id: uuidv4(), name: "", time: "" });
      }
      return configRound;
    }
    return [];
  };

  return (
    <FormLayout>
      <Link to="/" style={{ alignSelf: "flex-start" }}>
        返回主页
      </Link>
      <FormContainer>
        <CreateTimerFormTitle>创建新计时器</CreateTimerFormTitle>
        <Formik
          initialValues={formikInitialValues}
          onSubmit={(values, { setErrors, resetForm }) =>
            createFormSubmit(values, setErrors, resetForm)
          }
          validationSchema={Yup.object({
            timerName: Yup.string()
              .required("请输入计时器名称")
              .max(100, "计时器名称必须少于100字"),
            timerCode: Yup.string()
              .min(5, "自订计时器代码须多于5字")
              .max(10, "自订计时器代码须少于10字")
              .matches(/^\d+$/, "自订计时器代码必须是数字"),
            premiumCode: Yup.string()
              .min(5, "VIP代码须多于5字")
              .max(20, "VIP代码须少于20字"),
            timerRound: Yup.array()
              .of(
                Yup.object().shape({
                  id: Yup.string().required(),
                  name: Yup.string()
                    .required("请输入环节名称")
                    .max(50, "环节名称必须少过50字"),
                  roundType: Yup.string().matches(
                    /(single|double|quadruple|free)/,
                    "请选择环节种类"
                  ),
                  config: Yup.array().of(
                    Yup.object().shape({
                      id: Yup.string().required(),
                      name: Yup.string().max(100, "名称必须少过100字"),
                      time: Yup.number("时间必须是数字")
                        .moreThan(0, "时长必须大于0")
                        .required("请输入时长"),
                    })
                  ),
                })
              )
              .min(1, "请创建至少一个环节"),
          })}>
          {({
            values,
            handleSubmit,
            errors,
            touched,
            getFieldProps,
            handleChange,
            setFieldValue,
            setFieldError,
            setErrors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="form-group my-3">
                <label htmlFor="timerName">计时器名称</label>
                <bsForm.Control
                  type="text"
                  name="timerName"
                  placeholder="请输入计时器名称"
                  isInvalid={!!errors.timerName && touched.timerName}
                  {...getFieldProps("timerName")}
                />
                <bsForm.Control.Feedback type="invalid">
                  {errors.timerName}
                </bsForm.Control.Feedback>
              </div>
              <div className="form-group my-3">
                <VipLabel
                  htmlFor="timerName"
                  content="VIP代码"
                  labelTitle="VIP代码"
                  overLayContent={
                    <>
                      所有计时器使用次数为3次，并将在3日后自动失效。
                      <br />
                      使用VIP代码创造的计时器将不受此限制。
                      <br />
                      一个VIP代码同时只可以激活一个计时器。
                      <br />
                      欲知更多详情请联络
                      <a
                        href={process.env.REACT_APP_CONTACT_EMAIL_URL}
                        target="_blank"
                        rel="noreferrer">
                        hehsdebateteam@gmail.com
                      </a>
                    </>
                  }
                />
                <bsForm.Control
                  type="text"
                  name="premiumCode"
                  placeholder="如果有VIP代码,请在此输入"
                  isInvalid={!!errors.premiumCode && touched.premiumCode}
                  {...getFieldProps("premiumCode")}
                />
                <bsForm.Control.Feedback type="invalid">
                  {errors.premiumCode}
                </bsForm.Control.Feedback>
              </div>
              <div className="form-group my-3">
                <label htmlFor="timerCode">计时器自订代码</label>
                <bsForm.Control
                  type="text"
                  name="timerCode"
                  placeholder="请输入自订代码"
                  className="form-control"
                  isInvalid={!!errors.timerCode}
                  {...getFieldProps("timerCode")}
                  onBlur={() =>
                    checkTimerCodeExist(values.timerCode, setFieldError)
                  }
                />
                <bsForm.Control.Feedback type="invalid">
                  {errors.timerCode}
                </bsForm.Control.Feedback>
              </div>
              <FieldArray
                name="timerRound"
                render={(arrayHelpers) => (
                  <>
                    {values.timerRound && values.timerRound.length > 0
                      ? values.timerRound.map((timer, index) => {
                          return (
                            <div key={timer.id}>
                              <hr />
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <h1>环节 #{index + 1}</h1>
                                </div>
                                <div
                                  onClick={() => arrayHelpers.remove(index)}
                                  className="btn btn-danger"
                                  style={{}}>
                                  X
                                </div>
                              </div>
                              <div className="form-group my-3">
                                <label htmlFor={`timerRound.${index}.name`}>
                                  环节名称
                                </label>
                                <bsForm.Control
                                  type="text"
                                  className="form-control"
                                  placeholder="请输入环节名称"
                                  name={`timerRound.${index}.name`}
                                  isInvalid={errorExist(
                                    errors,
                                    touched,
                                    `timerRound.${index}.name`
                                  )}
                                  {...getFieldProps(`timerRound.${index}.name`)}
                                />

                                {errorExist(
                                  errors,
                                  touched,
                                  `timerRound.${index}.name`
                                ) && (
                                  <bsForm.Control.Feedback type="invalid">
                                    {errors.timerRound[index].name}
                                  </bsForm.Control.Feedback>
                                )}
                              </div>
                              {/* {!isEmpty(error.name) && <small>{error.name}</small>} */}
                              <div className="form-group my-3">
                                <label
                                  htmlFor={`timerRound.${index}.roundType`}>
                                  环节种类
                                </label>
                                <bsForm.Control
                                  as="select"
                                  name={`timerRound.${index}.roundType`}
                                  className="form-select"
                                  {...getFieldProps(
                                    `timerRound.${index}.roundType`
                                  )}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldValue(
                                      `timerRound.${index}.config`,
                                      getTimerRoundConfig(e.target.value)
                                    );
                                  }}
                                  isInvalid={errorExist(
                                    errors,
                                    touched,
                                    `timerRound.${index}.roundType`
                                  )}>
                                  <option value={"none"} disabled hidden>
                                    选择一种环节种类
                                  </option>
                                  {roundTypeSelect.map((obj) => (
                                    <option key={obj.value} value={obj.value}>
                                      {obj.ch}
                                    </option>
                                  ))}
                                </bsForm.Control>
                                {errorExist(
                                  errors,
                                  touched,
                                  `timerRound.${index}.roundType`
                                ) && (
                                  <bsForm.Control.Feedback type="invalid">
                                    {errors.timerRound[index].roundType}
                                  </bsForm.Control.Feedback>
                                )}
                              </div>
                              {timer.roundType === "single" && (
                                <div className="form-control">
                                  <label
                                    htmlFor={`timerRound.${index}.isFirst`}>
                                    正方？
                                  </label>
                                  <bsForm.Check
                                    checked={timer.isFirst}
                                    name={`timerRound.${index}.isFirst`}
                                    {...getFieldProps(
                                      `timerRound.${index}.isFirst`
                                    )}
                                  />
                                </div>
                              )}
                              <FieldArray
                                name={`timerRound.${index}.config`}
                                render={(arrayHelpers) => (
                                  <div className="d-flex flex-wrap">
                                    {values.timerRound &&
                                    values.timerRound.length > 0 &&
                                    values.timerRound[index] &&
                                    values.timerRound[index].config &&
                                    values.timerRound[index].config.length > 0
                                      ? values.timerRound[index].config.map(
                                          (config, configIndex) => {
                                            return (
                                              <div
                                                key={config.id}
                                                style={{
                                                  width: "calc(50% - 1rem )",
                                                  margin:
                                                    "1.0rem 1.0rem 0.5rem 0rem",
                                                }}>
                                                <div className="form-control">
                                                  <label
                                                    htmlFor={`timerRound.${index}.config.${configIndex}.name`}>
                                                    名称
                                                    {values.timerRound[index]
                                                      .roundType !== "single"
                                                      ? configIndex >=
                                                        values.timerRound[index]
                                                          .config.length /
                                                          2
                                                        ? "（反方）"
                                                        : "（正方）"
                                                      : ""}
                                                  </label>
                                                  <bsForm.Control
                                                    type="text"
                                                    placeholder={
                                                      values.timerRound[index]
                                                        .roundType !== "single"
                                                        ? `请输入名称 ${
                                                            configIndex >=
                                                            values.timerRound[
                                                              index
                                                            ].config.length /
                                                              2
                                                              ? "（反方）"
                                                              : "（正方）"
                                                          }`
                                                        : "请输入名称"
                                                    }
                                                    name={`timerRound.${index}.config.${configIndex}.name`}
                                                    className="form-control"
                                                    isInvalid={errorExist(
                                                      errors,
                                                      touched,
                                                      `timerRound.${index}.config.${configIndex}.name`
                                                    )}
                                                    {...getFieldProps(
                                                      `timerRound.${index}.config.${configIndex}.name`
                                                    )}
                                                  />
                                                  {errorExist(
                                                    errors,
                                                    touched,
                                                    `timerRound.${index}.config.${configIndex}.name`
                                                  ) && (
                                                    <bsForm.Control.Feedback type="invalid">
                                                      {
                                                        errors.timerRound[index]
                                                          .config[configIndex]
                                                          .name
                                                      }
                                                    </bsForm.Control.Feedback>
                                                  )}
                                                  <label
                                                    htmlFor={`timerRound.${index}.config.${configIndex}.time`}>
                                                    时长（秒）
                                                  </label>
                                                  <bsForm.Control
                                                    type="number"
                                                    placeholder="时长（秒）"
                                                    name={`timerRound.${index}.config.${configIndex}.time`}
                                                    className="form-control"
                                                    isInvalid={errorExist(
                                                      errors,
                                                      touched,
                                                      `timerRound.${index}.config.${configIndex}.time`
                                                    )}
                                                    {...getFieldProps(
                                                      `timerRound.${index}.config.${configIndex}.time`
                                                    )}
                                                  />
                                                  {errorExist(
                                                    errors,
                                                    touched,
                                                    `timerRound.${index}.config.${configIndex}.time`
                                                  ) && (
                                                    <bsForm.Control.Feedback type="invalid">
                                                      {
                                                        errors.timerRound[index]
                                                          .config[configIndex]
                                                          .time
                                                      }
                                                    </bsForm.Control.Feedback>
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                      : null}
                                  </div>
                                )}
                              />
                            </div>
                          );
                        })
                      : null}
                    <bsForm.Control
                      as="button"
                      onClick={() => {
                        arrayHelpers.push({
                          id: uuidv4(),
                          name: "",
                          roundType: "none",
                          isFirst: true,
                          config: [],
                        });
                      }}
                      isInvalid={
                        errorExist(errors, touched, `timerRound`) &&
                        typeof errors.timerRound === "string"
                      }
                      type="button"
                      className="btn btn-primary mt-3 w-100">
                      增加环节
                    </bsForm.Control>
                    {errorExist(errors, touched, `timerRound`) && (
                      <div className="invalid-feedback">
                        {typeof errors.timerRound === "string" &&
                          errors.timerRound}
                      </div>
                    )}
                  </>
                )}
              />

              <div className="form-group">
                <button
                  type="submit"
                  className={`btn btn-success mt-3 w-100 ${
                    values.timerRound.length <= 0 || isSendingData
                      ? "disabled"
                      : ""
                  }`}>
                  生成计时器
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  );
};

// ------ Styled Component START ------
const FormLayout = styled.div`
  padding: 5rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainer = styled.div`
  width: 50%;
  border: 1px solid black;
  @media (max-width: 1300px) {
    width: 80%;
  }
  padding: 2rem;
`;

const CreateTimerFormTitle = styled.h1`
  font-size: 4rem;
  font-weight: 400;
`;
// ------ Styled Component END ------

export default CreateTimer;
