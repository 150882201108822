const convertTime = (totalTime) => {
  let minutes = Math.floor(totalTime / 60);
  let seconds = totalTime % 60;
  let finalTime =
    str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
  return finalTime;
};

function str_pad_left(string, pad, length) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}

export default convertTime;
