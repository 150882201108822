// TODO Disable sound effect happened in other round

// @import packages
import React, { useState, useEffect } from "react"; // React
import { Link } from "react-router-dom"; // Route
import { useFormik } from "formik"; // Form
import * as Yup from "yup"; // Form Validation
import { toast } from "react-toastify"; // Toast

// @import utils
import isEmpty from "../utils/isEmpty";
import axios from "../utils/axios";

// @import components
import TimerPage from "./TimerPage";
import TimerTitle from "../components/timer/TimerTitle";

// @import assets
import HeaderImg from "../assets/img/header.png";

// @import styling
import styled from "styled-components";
import { Row, Col, Form } from "react-bootstrap";

const HomePage = () => {
  const [timerObj, setTimerObj] = useState({});
  const [offsetY, setOffsetY] = useState(0);
  const [roundCount, setRoundCount] = useState(0);
  const [scrollDisabled, setScrollDisabled] = useState(false);
  const animationTime = 1000;

  useEffect(() => {
    document.title = "辩论计时器";
  }, []);

  // @desc Initializing formik
  const formik = useFormik({
    initialValues: { timerCode: "" },
    validationSchema: Yup.object({
      timerCode: Yup.string()
        .required("请输入计时器代码")
        .min(5, "计时器代码须多于5字")
        .max(10, "计时器代码须少于10字")
        .matches(/^\d+$/, "计时器代码必须是数字"),
    }),
    onSubmit: (values) => searchTimer(values),
  });

  // @desc usseEffects
  // Change round count whenever timer object changes
  useEffect(() => {
    if (!isEmpty(timerObj)) {
      setRoundCount(timerObj.timerRound.length);
    }
  }, [timerObj]);

  // When user scroll over, return to first / last page
  useEffect(() => {
    if (offsetY < 0) {
      setOffsetY(roundCount * 100);
    }
    if (offsetY > roundCount * 100) {
      setOffsetY(0);
    }
  }, [offsetY, roundCount]);

  // @desc Functions
  const searchTimer = ({ timerCode }) => {
    const data = {
      timerCode: timerCode.toString(),
    };
    axios
      .post("/gettimer", data)
      .then((res) => {
        setTimerObj(res.data.timer);
        if (!res.data.timer.isPremium) {
          switch (res.data.timer.quotaLeft) {
            case 0:
              toast.warning(
                `使用成功。计时器剩余使用次数为0，计时器将被删除。`,
                {
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                }
              );
              break;
            case 1:
            case 2:
            default:
              toast.info(
                `使用成功。计时器剩余使用次数: ${res.data.timer.quotaLeft}次`,
                {
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                }
              );
              break;
          }
        }
      })
      .catch((err) => {
        toast.error("计时器不存在", {
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
      });
  };

  const onePageScroll = (e) => {
    if (!scrollDisabled) {
      if (e.nativeEvent.wheelDelta > 0) {
        setOffsetY(offsetY - 100);
      } else {
        setOffsetY(offsetY + 100);
      }
      setScrollDisabled(true);
      setTimeout(() => setScrollDisabled(false), animationTime);
    }
  };

  return (
    <>
      {isEmpty(timerObj) ? (
        <div className="container d-flex flex-column w-100 h-100 justify-content-center align-items-center">
          <Row className="w-100 justify-content-center">
            <Col sm={10} lg={6} className="align-items-center">
              <div className="container border p-4 rounded">
                <img
                  src={HeaderImg}
                  style={{ width: "100%" }}
                  alt="Art by @tein"
                />
                <h1 className="inline">请输入计时器代码</h1>
                <Form onSubmit={formik.handleSubmit} noValidate>
                  <div className="input-group">
                    <Form.Control
                      type="text"
                      name="timerCode"
                      placeholder="计时器代码"
                      onChange={formik.onChange}
                      onBlur={formik.onBlur}
                      {...formik.getFieldProps("timerCode")}
                      isInvalid={
                        formik.submitCount > 0 &&
                        !!formik.errors.timerCode &&
                        formik.touched.timerCode
                      }
                    />
                    <div className="input-group-append ml-3">
                      <button type="submit" className="btn btn-outline-success">
                        搜寻计时器
                      </button>
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.timerCode}
                    </Form.Control.Feedback>
                  </div>
                </Form>
                <hr />
                <Link to="/create">创建新计时器</Link>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <OnePageScrollContainer>
          <OnePageScrollDiv
            offsetY={offsetY}
            animationTime={animationTime}
            onWheel={(e) => onePageScroll(e)}>
            <TimerTitle title={timerObj.timerName} />
            {timerObj.timerRound.map((round) => (
              <TimerPage key={round.id} round={round} />
            ))}
          </OnePageScrollDiv>
          <ToggleTimerContainer>
            <StageButton
              active={offsetY === 0}
              onClick={() => setOffsetY(0)}></StageButton>
            {timerObj.timerRound.map((_, index) => {
              return (
                <StageButton
                  key={index}
                  active={offsetY / 100 - 1 === index}
                  onClick={() => setOffsetY((index + 1) * 100)}></StageButton>
              );
            })}
          </ToggleTimerContainer>
        </OnePageScrollContainer>
      )}
    </>
  );
};

// ------ Styled Component START ------

const OnePageScrollDiv = styled.div`
  width: 100%;
  height: 100%;
  transition: transform ${(props) => props.animationTime}ms ease-in-out 0s;
  transform: translate3d(0px, ${(props) => -props.offsetY}%, 0px);
`;
const OnePageScrollContainer = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 100vw;
`;

const ToggleTimerContainer = styled.div`
  position: fixed;
  width: 3rem;
  height: 100%;
  top: 0rem;
  right: 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StageButton = styled.button`
  ${(props) => (props.active ? `background-color:green;` : "")}
  height:0.5rem;
  background-color: ${(props) => (props.active ? `black` : "white")};
  width: 0.5rem;
  border-radius: 1rem;
  border: 0.2rem solid black !important;
  border-color: black !important;
  padding: 0.5rem;
  display: inline-block;
  margin: 1rem 2rem;

  transition: background-color 1000ms ease-in-out 0s;
`;

// ------ Styled Component END ------

export default HomePage;
