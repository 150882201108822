// @import packages
import React from "react";

// @import styling
import styled from "styled-components";

const TimerTitle = ({ title }) => {
  return (
    <BgDiv>
      <TitleCotnainer>
        <h1 className="display-1">{title}</h1>
      </TitleCotnainer>
    </BgDiv>
  );
};

const BgDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleCotnainer = styled.div`
  width: 60%;
  text-align: center;
`;

export default TimerTitle;
