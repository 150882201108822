import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import styled from "styled-components";

const VipLabel = ({ htmlFor, content, labelTitle, overLayContent }) => {
  const tippyOverLayContent = (
    <>
      <h6>{labelTitle}</h6>
      <BreakLine />
      {overLayContent}
    </>
  );

  // const controlShow = {
  //   onMouseEnter: () => setShowLabel(true),
  //   onMouseLeave: () => setShowLabel(false),
  // };
  // return (
  //   <>
  //     <label ref={target} htmlFor={htmlFor} {...controlShow}>
  //       {content}
  //     </label>
  //   </>
  // );
  return (
    <>
      <label htmlFor={htmlFor}>{content}</label>
      <Tippy
        content={tippyOverLayContent}
        placement="right"
        trigger="click"
        interactive="true">
        <SvgContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-info-circle ml-1"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </SvgContainer>
      </Tippy>
    </>
  );

  // const [show, setShow] = useState(false);
  // const target = useRef(null);

  // return (
  //   <>
  //     <Button ref={target} onClick={() => setShow(!show)}>
  //       Click me!
  //     </Button>
  //     <Overlay target={target.current} show={show} placement="right">
  //       {(props) => (
  //         <Tooltip
  //           id="overlay-example"
  //           {...props}
  //           arrowProps={{ placement: "right" }}>
  //           My Tooltip
  //         </Tooltip>
  //       )}
  //     </Overlay>
  //   </>
  // );
};
const SvgContainer = styled.div`
  margin-left: 5px;
  display: inline-block;
  cursor: pointer;
`;

const BreakLine = styled.div`
  background-color: white;
  width: auto;
  height: 1px;
`;
export default VipLabel;
