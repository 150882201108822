// @import packages
import React from "react";
import styled from "styled-components";

// @import utils
import convertTime from "../../utils/convertTime";

const BaseTimer = ({
  name = "",
  time = 0,
  timerActive = false,
  handleStart = () => {},
  showControlsAfterStart = false,
  handlePausePlay = () => {},
  timerPlaying = false,
  handleReset = () => {},
  showIsActive = null,
}) => {
  return (
    <BaseTimerDiv>
      <BaseTimerNameHeading>{name}</BaseTimerNameHeading>
      <BaseTimerTimeLeft showIsActive={showIsActive}>
        {convertTime(time)}
      </BaseTimerTimeLeft>
      {!timerActive ? (
        <ControlDiv onClick={handleStart} className="btn btn-lg btn-success">
          开始
        </ControlDiv>
      ) : (
        showControlsAfterStart && (
          <ControlDiv>
            {time > 0 && (
              <button
                onClick={handlePausePlay}
                className={`btn btn-lg btn-${
                  timerPlaying ? "danger" : "success"
                } mx-3`}>
                {timerPlaying ? "暂停" : "继续"}
              </button>
            )}
            <button
              onClick={handleReset}
              className="btn btn-lg btn-warning mx-3">
              重置
            </button>
          </ControlDiv>
        )
      )}
    </BaseTimerDiv>
  );
};

// ------ Styled Component START ------

const BaseTimerDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 10rem;
  width: 30rem;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
`;

const BaseTimerNameHeading = styled.h1`
  font-size: 3.5rem;
  margin: 1rem 0rem;
`;

const BaseTimerTimeLeft = styled.h1`
  ${(props) =>
    props.showIsActive
      ? "text-decoration:underline;text-decoration-color:#198754;"
      : ""}
  font-size: 4rem;
  margin: 1rem 0rem;
`;

const ControlDiv = styled.div`
  margin: 1rem 0rem;
  display: flex;
`;

// ------ Styled Component END ------

export default BaseTimer;
