// @import packages
import React, { useState, useEffect } from "react";

// @import components
import BaseTimer from "./BaseTimer.js";
import { lastAudio, endAudio } from "../../assets/audio/audio.js";

const Timer = ({ timerObj }) => {
  const [time, setTime] = useState(timerObj.time);
  const [timerPlaying, setTimerPlaying] = useState(false);
  const [timerActive, setTimerActive] = useState(false);

  // @desc UseEffects
  useEffect(() => {
    let interval = null;
    if (timerPlaying && timerActive) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timerPlaying, timerActive]);

  // 30 seconds and end notify bell
  useEffect(() => {
    if (time === 30) {
      lastAudio.play();
    }
    if (time === 0) {
      endAudio.play();
      setTimerPlaying(false);
    }
  }, [time]);

  // @desc Functions
  const handleStart = () => {
    setTimerPlaying(true);
    setTimerActive(true);
  };

  const handlePausePlay = () => {
    setTimerPlaying(!timerPlaying);
  };

  const handleReset = () => {
    setTimerActive(false);
    setTime(timerObj.time);
  };

  return (
    <BaseTimer
      name={timerObj.name}
      time={time}
      timerActive={timerActive}
      handleStart={handleStart}
      showControlsAfterStart={true}
      handlePausePlay={handlePausePlay}
      timerPlaying={timerPlaying}
      handleReset={handleReset}
    />
  );
};

export default Timer;
