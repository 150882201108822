// @import packages
import React from "react";

// @import components
import TimerGroup from "../components/timer/TimerGroup";

// @import styling
import styled from "styled-components";

const TimerPage = ({ round }) => {
  return (
    <BgDiv>
      <h1 className="display-2">{round.name}</h1>
      <TimerGroup roundType={round.roundType} config={round.config} />
    </BgDiv>
  );
};

// ------ Styled Component START ------
const BgDiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
// ------ Styled Component END ------

export default TimerPage;
