// TODO save last competition data
import CreateTimer from "./pages/CreateTimer";
import HomePage from "./pages/HomePage";
import { Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/layout/Layout";

function App() {
  return (
    <MainContainer>
      <Layout />
      <Switch>
        <Route exact path="/" render={(props) => <HomePage />} />
        <Route path="/create" render={(props) => <CreateTimer />} />
      </Switch>
      <ToastContainer limit={5} />
    </MainContainer>
  );
}

const MainContainer = styled.div`
  min-height: 100vh;
  height: 100vh;
  max-width: 100vw;
`;
export default App;
