import React from "react";
import { toast } from "react-toastify";

const AlertTimerCode = ({ timerCode }) => {
  return (
    <div
      onClick={() => {
        navigator.clipboard.writeText(timerCode);
        toast.success("复制成功", { autoClose: 1000 });
      }}>
      创建成功，计时器代码为 {timerCode} （点我复制）
    </div>
  );
};

export default AlertTimerCode;
